<template>
  <div>
    <button
      class="btn btn-info btn-sm"
      data-toggle="tooltip"
      data-placement="top"
      title="New"
      @click="fnShowModal()"
    >
      New
    </button>

    <b-modal ref="modal" hide-footer title="Document type - new">
      <div class="d-block">
        <form v-on:submit.prevent="createNew">
          <div class="form-group">
            <b-form-select
              autocomplete="off_documentCategory"
              placeholder=" "
              v-model="document_category_id"
              value-field="id"
              text-field="name"
              :options="gettDocumentCategoryAll"
            ></b-form-select>
            <label class="floatingLabel vueBootSelect" for="documentCategory"
              >Document category</label
            >
            <div class="invalid-feedback" v-if="errors.document_category_id">
              {{ errors.document_category_id[0] }}
            </div>
          </div>
          <div class="form-group">
            <input
              class="form-control"
              type="text"
              id="name"
              autocomplete="off_name"
              placeholder=" "
              v-model="name"
            />
            <label class="floatingLabel" for="mobileNo">Name</label>
            <div class="invalid-feedback" v-if="errors.name">
              {{ errors.name[0] }}
            </div>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="NSS Name"
              v-model="nss_name"
            />
            <label class="floatingLabel" for="name">NSS Name</label>
            <div class="invalid-feedback" v-if="errors.nss_name">
              {{ errors.nss_name[0] }}
            </div>
          </div>
          <div>
            <b-form-group
              label="Individual radios"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="1"
                >Upload image</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="2"
                >Reference #| Upload image</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="3"
                >Given Name| Family name| Passport#| Country| Upload image|
                Upload current foreign passport</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="4"
                >Passport type| Country| Number| Given name| Middle name| Family
                name| Upload image</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="5"
                >Issuing agency| Given name| Middle name| Family name| Licence#
                |Upload image</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="6"
                >Passport type| Country number| Given name| Middle name| Family
                name| Upload image| Upload valid Australian visa</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="7"
                >Card type| Medicare#| Individual reference#| Name per document|
                Expiry date upload image</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="8"
                >Provide reasons why you cannot provide the
                document</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="9"
                >State| Given name| Middle name| Family name| Registration #|
                Date Certificate Issued| Upload document/s</b-form-radio
              >
            </b-form-group>
          </div>
          <div class="text-right mt1500">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              @click="hideModal"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-info btn-sm">Save</button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as documentTypeService from "../../services/documentType.service";
export default {
  name: "ModalDocumentTypeNew",
  components: {},
  data() {
    return {
      errors: {},
      name: "",
      document_category_id: "",
      nss_name: "",
      form_type_id: "",
    };
  },
  computed: {
    ...mapGetters(["gettDocumentCategoryAll"]),
  },
  methods: {
    ...mapActions(["fetchDocumentTypeAll"]),
    fnShowModal() {
      this.showModal();
    },
    hideModal() {
      this.$refs.modal.hide();
    },
    showModal() {
      this.$refs.modal.show();
    },

    createNew: async function () {
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("document_category_id", this.document_category_id);
      formData.append("form_type_id", this.form_type_id);
      formData.append("nss_name", this.nss_name);
      try {
        this.errors = {};

        const response = await documentTypeService.add(formData);
        this.hideModal();
        this.fetchDocumentTypeAll();

        messageService.fnSweetAlertSuccessToast(
          "Document Type - new",
          "added successfully"
        );

        this.name = "";
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Document Type - new",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Document Type - new",
              "Something wrong"
            );
            break;
        }
      }
    },
  },
  created() {},
};
</script>