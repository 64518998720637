<template>
  <div>
    <a @click="fnShowModal()" class="p-2"
      ><i class="far fa-edit text-info"></i
    ></a>

    <b-modal ref="modal" hide-footer title="Document type - update">
      <div class="d-block">
        <form>
          <div class="form-group">
            <b-form-select
              autocomplete="off_documentCategory"
              placeholder=" "
              v-model="document_category_id"
              value-field="id"
              text-field="name"
              :options="gettDocumentCategoryAll"
            ></b-form-select>
            <label class="floatingLabel vueBootSelect" for="documentCategory"
              >Document category</label
            >
            <div class="invalid-feedback" v-if="errors.document_category_id">
              {{ errors.document_category_id[0] }}
            </div>
          </div>

          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="Document Type"
              v-model="name"
            />
            <label class="floatingLabel" for="name">Type</label>
            <div class="invalid-feedback" v-if="errors.name">
              {{ errors.name[0] }}
            </div>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="NSS Name"
              v-model="nss_name"
            />
            <label class="floatingLabel" for="name">NSS Name</label>
            <div class="invalid-feedback" v-if="errors.nss_name">
              {{ errors.nss_name[0] }}
            </div>
          </div>
          <div>
            <b-form-group label="Form type" v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="1"
                >Upload image</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="2"
                >Reference #| Upload image</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="3"
                >Given Name| Family name| Passport#| Country| Upload image|
                Upload current foreign passport</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="4"
                >Passport type| Country| Number| Given name| Middle name| Family
                name| Upload image</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="5"
                >Issuing agency| Given name| Middle name| Family name| Licence#
                |Upload image</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="6"
                >Passport type| Country number| Given name| Middle name| Family
                name| Upload image| Upload valid Australian visa</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="7"
                >Card type| Medicare#| Individual reference#| Name per document|
                Expiry date upload image</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="8"
                >Provide reasons why you cannot provide the
                document</b-form-radio
              >
              <b-form-radio
                v-model="form_type_id"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="9"
                >State| Given name| Middle name| Family name| Registration #|
                Date Certificate Issued| Upload document/s</b-form-radio
              >
            </b-form-group>
          </div>

          <b-form-checkbox
            id="checkbox-1"
            v-model="status"
            name="checkbox-1"
            value="1"
            unchecked-value="0"
          >
            Status
          </b-form-checkbox>
          <div class="text-right mt1500">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              @click="hideModal"
            >
              Cancel
            </button>
            <button
              type="button"
              @click="fnUpdate()"
              class="btn btn-info btn-sm"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as documentTypeService from "../../services/documentType.service";
export default {
  name: "ModalDocumentTypeEdit",
  props: ["propsData"],
  components: {},
  data() {
    return {
      errors: {},
      id: "",
      name: "",
      document_category_id: "",
      nss_name: "",
      form_type_id: "",
      status: "",
    };
  },
  computed: {
    ...mapGetters(["gettDocumentTypeAll", "gettDocumentCategoryAll"]),
  },
  methods: {
    ...mapActions(["fetchDocumentTypeAll"]),
    fnShowModal() {
      this.fnGetValue(this.propsData);
      this.showModal();
    },
    hideModal() {
      this.$refs.modal.hide();
    },
    showModal() {
      this.$refs.modal.show();
    },

    fnGetValue(props) {
      this.id = props.rowData.id;
      this.status = props.rowData.status;
      this.name = props.rowData.name;
      this.document_category_id = props.rowData.document_category_id;
      this.nss_name = props.rowData.nss_name;
      this.form_type_id = props.rowData.form_type_id;
    },

    fnUpdate: async function () {
      const obj = {
        name: this.name,
        document_category_id: this.document_category_id,
        nss_name: this.nss_name,
        form_type_id: this.form_type_id,
        status: this.status,
      };

      try {
        this.errors = {};

        await documentTypeService.update(this.id, obj);

        this.fetchDocumentTypeAll();
        messageService.fnSweetAlertSuccessToast(
          "Document type",
          "Updated successfully "
        );
        this.hideModal();

        this.id = "";
        this.name = "";
        this.status = "";
        this.document_category_id = "";
        this.form_type_id = "";
        this.nss_name = "";
      } catch (error) {
        messageService.fnSweetAlertErrorToast(
          "Document type - update",
          error.response.data.message
        );
      }
    },
  },
};
</script>