<template>
  <div class="p-4">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-6 text-left">
            <h4>Document types</h4>
          </div>
          <div class="col-6 text-right">
            <appModalDocumentTypeNew></appModalDocumentTypeNew>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive table-sm">
          <!-- Datatable -->
          <DataTable
            :header-fields="headerFields"
            :sort-field="sortField"
            :sort="sort"
            :data="data || []"
            :is-loading="isLoading"
            :css="datatableCss"
            not-found-msg="Items not found"
            @on-update="dtUpdateSort"
            track-by="firstName"
          >
            <!-- Action button slot -->
            <template v-slot:actions="props">
              <appModalDocumentTypeEdit
                :propsData="props"
                class="d-inline"
              ></appModalDocumentTypeEdit>
            </template>

            <!--
              Pagination component as a slot, but could be drag out from Database element
            -->
            <template v-slot:pagination>
              <Pagination
                :page="currentPage"
                :total-items="totalItems"
                :items-per-page="itemsPerPage"
                :css="paginationCss"
                @on-update="changePage"
                @update-current-page="updateCurrentPage"
              />
            </template>

            <!--
              ItemsPerPage component as a slot, but could be drag out from Database element
            -->
            <div class="items-per-page" slot="ItemsPerPage">
              <label>Items per page</label>
              <ItemsPerPageDropdown
                :list-items-per-page="listItemsPerPage"
                :items-per-page="itemsPerPage"
                :css="itemsPerPageCss"
                @on-update="updateItemsPerPage"
              />
            </div>

            <!-- Spinner element as slot used when is-loading attribute is true -->
            <Spinner slot="spinner" />

            <!-- Custom element for Primary -->
            <div slot="custStatus" slot-scope="props">
              <div v-if="props.rowData.status == 1">
                <span class="badge badge-success">Active</span>
              </div>
              <div v-else>
                <span class="badge badge-danger">In-active</span>
              </div>
            </div>

            <div slot="custCategory" slot-scope="props">
              <div v-if="props.rowData.document_category">
                <span class="text-capitalize">{{
                  props.rowData.document_category.name
                }}</span>
              </div>
            </div>

            <div slot="custAddBy" slot-scope="props">
              <div v-if="props.rowData.add_user">
                <span class="text-capitalize">{{
                  props.rowData.add_user.first_name
                }}</span>
              </div>
            </div>

            <div slot="custEditBy" slot-scope="props">
              <div v-if="props.rowData.edit_user">
                <span class="text-capitalize">{{
                  props.rowData.edit_user.first_name
                }}</span>
              </div>
            </div>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "vue-simple-spinner";
import { DataTable, ItemsPerPageDropdown, Pagination } from "v-datatable-light";
import orderBy from "lodash.orderby";
import { mapGetters, mapActions } from "vuex";
import appModalDocumentTypeNew from "../../components/settings/ModalDocumentTypeNew.vue";
import appModalDocumentTypeEdit from "../../components/settings/ModalDocumentTypeEdit.vue";

const addZero = (value) => ("0" + value).slice(-2);
const formatDate = (value) => {
  if (value) {
    const dt = new Date(value);
    return `${addZero(dt.getDate())}/${addZero(
      dt.getMonth() + 1
    )}/${dt.getFullYear()}`;
  }
  return "";
};

export default {
  name: "documentTypeList",
  components: {
    DataTable,
    ItemsPerPageDropdown,
    Pagination,
    Spinner,
    appModalDocumentTypeNew,
    appModalDocumentTypeEdit,
  },

  data: function () {
    return {
      initialData: "",
      data: "",
      headerFields: [
        {
          name: "id",
          label: "Document id",
        },
        {
          name: "name",
          label: "Document name",
        },
        {
          name: "category",
          label: "Category",
          sortable: true,
          customElement: "custCategory",
        },
        {
          name: "form_type_id",
          label: "Form Id",
          sortable: true,
        },
        {
          name: "status",
          label: "Status",
          sortable: true,
          customElement: "custStatus",
        },
        {
          name: "add_by",
          label: "Add By",
          sortable: true,
          customElement: "custAddBy",
        },
        {
          name: "created_at",
          label: "Created",
          sortable: true,
          format: formatDate,
        },
        "__slot:actions",
      ],

      datatableCss: {
        table: "table table-bordered table-hover table-striped table-center",
        theadTh: "header-item",
        thWrapper: "th-wrapper",
        thWrapperCheckboxes: "th-wrapper checkboxes",
        arrowsWrapper: "arrows-wrapper",
        arrowUp: "arrow up",
        arrowDown: "arrow down",
        footer: "footer",
      },
      paginationCss: {
        paginationItem: "pagination-item",
        moveFirstPage: "move-first-page",
        movePreviousPage: "move-previous-page",
        moveNextPage: "move-next-page",
        moveLastPage: "move-last-page",
        pageBtn: "page-btn",
      },
      itemsPerPageCss: {
        select: "item-per-page-dropdown",
      },
      isLoading: false,
      sort: "asc",
      sortField: "firstName",
      listItemsPerPage: [5, 10, 20, 50, 100],
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: 160,
      hometown: null,
      createHeaderName: "created:header",
    };
  },

  computed: {
    ...mapGetters(["gettDocumentTypeAll"]),
  },
  methods: {
    ...mapActions(["fetchDocumentCategoryAll", "fetchDocumentTypeAll"]),

    dtUpdateSort: function ({ sortField, sort }) {
      const sortedData = orderBy(this.initialData, [sortField], [sort]);
      const start = (this.currentPage - 1) * Number(this.itemsPerPage);
      const end = this.currentPage * Number(this.itemsPerPage);
      this.data = sortedData.slice(start, end);
    },

    updateItemsPerPage: function (itemsPerPage) {
      this.itemsPerPage = Number(itemsPerPage);
      if (Number(itemsPerPage) >= this.initialData.length) {
        this.data = this.initialData;
      } else {
        this.data = this.initialData.slice(0, Number(itemsPerPage));
      }
    },

    changePage: function (currentPage) {
      this.currentPage = currentPage;
      const start = (currentPage - 1) * Number(this.itemsPerPage);
      const end = currentPage * Number(this.itemsPerPage);
      this.data = this.initialData.slice(start, end);
    },

    updateCurrentPage: function (currentPage) {
      this.currentPage = currentPage;
    },
  },
  created() {
    this.fetchDocumentTypeAll();
    this.fetchDocumentCategoryAll();
  },
  watch: {
    gettDocumentTypeAll: function (val) {
      this.totalItems = val.length;
      this.initialData = val;
      this.data = val.slice(0, 10);
    },
  },
};
</script>